@import 'style-guide';

.compactGlobalHeader {
	--logo-image-width: 121px;
	--logo-image-height: 18px;
	--lpheader-categorydropdown-offset: 50px;
	--lpheader-categorynav-bottom-border-offset: 1px;
	--lpheader-dropdown-offset: 41px;
	--lpHeader-hdrWrapper--right-width: 92px;
	--lpHeader-page-scrollY: 0px; // Needs unit for calc

	@media (min-width: 1020) {
		--lpHeader-hdrWrapper--right-width: 96px;
	}

	@media (min-width: 1190px) {
		--lpHeader-hdrWrapper--right-width: 104px;
	}

	@media (min-width: 1280px) {
		--lpHeader-hdrWrapper--right-width: 112px;
	}

	@media (min-width: 1600px) {
		--lpHeader-hdrWrapper--right-width: 150px;
	}

	#lpHeader {
		background: #fff;
		border-bottom: 1px solid #ccc;
		height: 60px;
		min-width: 960px;
		width: 100%;

		@media (min-width: 1090px) {
			--logo-image-width: 144px;
			--logo-image-height: 24px;
		}

		@media (min-width: 1190px) {
			--logo-image-width: 164px;
		}

		.logoImage {
			display: block;
			min-width: var(--logo-image-width);
			width: var(--logo-image-width);
		}

		.tagline {
			display: none;
		}

		&.Professional .logoImage {
			margin-top: 4px;
		}

		.headerCart {
			margin: -3px 0 5px;
			padding: 2px 1px 0 0;
			width: 30px;

			&__iconWrapper {
				margin: 2px 0 0 1px;
			}
		}

		.categoryDropDowns {
			margin-top: 9px !important;
			padding: 0 calc((100% - var(--lpheader-categorydropdown-content-width)) / 2);
			position: fixed; // Need it fixed to be full-width
			top: calc(var(--lpheader-categorydropdown-offset) - var(--lpheader-navwrapper-offset-y) - var(--lpHeader-page-scrollY));
			width: 100%;
			z-index: 99999;

			@media (max-width: 1400px) {
				padding: 0 100px;
			}

			@media (max-width: 1200px) {
				padding: 0 80px;
			}

			@media (max-width: 1136px) {
				padding: 0 40px;
			}

			@media (max-width: 1024px) {
				padding: 0 20px;
			}

			&__column {
				@media (max-width: 1628px) {
					--category-dropdown-column-gap: 45px;
				}

				@media (max-width: 1540px) {
					--category-dropdown-column-gap: 40px;
				}
			}
		}
	}

	#portfolioContainer-inspiration {
		@media (min-width: 1425px) {
			.categoryDropDowns__column {
				--category-dropdown-column-gap: 50px;
			}

			.menuItemBox {
				padding: 0 30px;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}
	}

	&--loading {
		#lpHeader {
			visibility: hidden;
		}
	}

	&--sticky {
		--lpheader-categorydropdown-offset: 66px;
		--lpheader-categorynav-bottom-border-offset: 0px; // requires unit bec it's used in calc()
		--lpheader-dropdown-offset: 42px;

		#lpHeader {
			animation: slideDownStickyGlobalHeader 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s 1;
			border-bottom-color: transparent;
			box-shadow: 0 4px 10px -2px rgba(102, 102, 102, 0.3);
			left: 0;
			max-width: none;
			position: fixed;
			right: 0;
			top: 0;
			z-index: 4;

			.categoryDropDowns {
				margin-top: -5px !important;
			}
		}

		@keyframes slideDownStickyGlobalHeader {
			from {
				transform: translate3d(0, -200%, 0);
			}

			to {
				transform: translate3d(0, 0, 0);
			}
		}
	}

	&:not(.compactGlobalHeader--searchExpanded) {
		#searchBtn {
			border: 0;
			border-radius: 2px;
			height: 24px;
			left: 1px;
			outline-offset: 1px;
			padding: 0;
			top: -3px;
			width: 24px;

			&::before {
				font-size: 2rem;
				left: 3px;
				top: 2px;
			}
		}

		.ie11-wrapper {
			display: none;
		}
	}

	#lpHeader-wrapper {
		@include responsiveWidthLayout($minWidth: 0, $maxWidth: none, $setFixedWidthOnMediaQuery: false);

		@media (min-width: 960px) and (max-width: 1180px) {
			width: calc(100% - 40px);
		}

		@media (max-width: 959px) {
			width: calc(100% - 30px);
		}

		.logoWrapper {
			margin-top: inherit;
		}
	}

	#logo {
		height: var(--logo-image-height);
		margin: 12px 0 0;
		outline-offset: 3px;
		z-index: 1;

		@media (min-width: 1090px) {
			margin-top: 11px;
		}

		@media (min-width: 1190px) {
			margin-top: 8px;
		}
	}

	#search[aria-hidden='true'] {
		border: 0;
		height: 0;
		outline: none;
		padding: 0;
		width: 0 !important;
	}

	#savedPortfolio-totalSaved--container + span,
	#portfolioContainer .headerCart__iconWrapper + span,
	#hdrPortal-siteShortcuts .headerDropDowns-menu {
		display: none !important;
	}

	#lpHeader-content {
		border-bottom: 0;
	}

	#lpHeader-navWrapper {
		--lpheader-navwrapper-margin-x: 7px;
		--lpheader-navwrapper-offset-y: 0px; // unit required for calc()
		margin: 0 var(--lpheader-navwrapper-margin-x);
		width: calc(100% - var(--logo-image-width) - var(--lpHeader-hdrWrapper--right-width));

		@media (min-width: 960px) {
			--lpheader-navwrapper-offset-y: 2px;
		}

		@media (min-width: 1020px) {
			--lpheader-navwrapper-offset-y: 2px;
		}

		@media (min-width: 1090px) {
			--lpheader-navwrapper-offset-y: 2px;
		}

		@media (min-width: 1280px) {
			max-width: 100%;
		}
	}

	&--loading {
		#lpHeader-navWrapper {
			width: 100%;
		}
	}

	#lpHeader-navWrapper--nav {
		--lpheader-categorydropdown-content-width: calc(100% - var(--logo-image-width) - var(--lpHeader-hdrWrapper--right-width) - (2 * var(--lpheader-navwrapper-margin-x)));
		transition: width 0.5s ease;
		width: 100%;

		> li {
			padding: 0;
		}

		.aNavBtn {
			font-size: 1.1rem;
			height: 41px;
			letter-spacing: -0.02em;
			position: relative;
			transition: font-size 0.5s ease;

			@media (min-width: 1020px) {
				font-size: 1.2rem;
			}

			@media (min-width: 1160px) {
				font-size: 1.3rem;
			}

			@media (min-width: 1220px) {
				font-size: 1.35rem;
			}

			@media (min-width: 1240px) {
				font-size: 1.4rem;
			}

			@media (min-width: 1340px) {
				font-size: 1.5rem;
			}
		}

		// default bottom border of active category is not vertically centered in compact mode
		// implement it differently in compact mode to make focus outline vertically centered
		.notTouch:hover .aNavBtn,
		.aNavBtn.hover,
		.selected .aNavBtn {
			border-bottom: 0;

			&::before {
				background: var(--header-nav-hover-line-color);
				bottom: calc(#{- ($categoryNavHoverBottomBorderWidth * 2)} + var(--lpheader-navwrapper-offset-y) + var(--lpheader-categorynav-bottom-border-offset));
				content: '';
				display: block;
				height: $categoryNavHoverBottomBorderWidth;
				left: 0;
				position: absolute;
				width: 100%;
				z-index: 999999;
			}
		}
	}

	#lpHeader-hdrWrapper {
		justify-content: center;
		margin: 10px 0 0;
		position: relative;
	}

	#lpHeader-hdrWrapper--right {
		margin-top: 7px;
		width: var(--lpHeader-hdrWrapper--right-width);
	}

	#searchContainer {
		margin: 0;
	}

	#portfolioContainer {
		margin: -1px 0 0;

		// adjustments to prevent cart icon outline to be covered by cart count
		&.notZero {
			margin-top: -2px;

			.headerCart {
				height: 32px;
				margin-right: -10px;
				outline-offset: 3px;
				width: 40px;
			}
		}
	}

	#savedPortfolio-totalSaved {
		&--container {
			margin: 0;
		}

		.headerDropDowns-title {
			border-bottom: 0 !important;
			height: 22px;
			margin: 0 !important;
			outline-offset: 2px;
			padding: 0;
			width: 22px;

			&:focus {
				margin-top: -1px !important;
				padding-top: 1px;
			}
		}
	}

	.portfolioContainer-savedPortfolioWrapper{
		margin-left: unset;
	}

	#portfolioContainer-savedPortfolio {
		border-bottom: 0;
		margin: 0 8px 7px;

		@media (min-width: 1020) {
			margin-left: 10px;
			margin-right: 10px;
		}

		@media (min-width: 1190px) {
			margin-left: 14px;
			margin-right: 14px;
		}

		@media (min-width: 1280px) {
			margin-left: 18px;
			margin-right: 18px;
		}

		@media (min-width: 1600px) {
			margin-left: 35px;
			margin-right: 35px;
		}

		.headerDropDowns-menu {
			left: auto;
			right: -11px;
			top: var(--lpheader-dropdown-offset);

			&::before {
				background: var(--header-nav-hover-line-color);
				content: '';
				display: block;
				height: 5px;
				position: absolute;
				right: 0;
				top: -6px;
				width: 42px;
			}
		}
	}

	.portfolioContainer-feature.hover {
		border-bottom: 0 !important;
		margin-bottom: 7px !important;
	}

	&--searchExpanded {
		#lpHeader-hdrWrapper {
			justify-content: space-between;
		}

		#lpHeader-navWrapper {
			display: none;
		}

		#lpHeader-hdrWrapper--right {
			margin-bottom: 0;
			margin-top: 5px;
			width: auto;
		}

		#searchContainer {
			bottom: 4px;
			margin-left: 10%;
		}

		#searchBtn::before {
			font-size: 2rem;
		}

		#portfolioContainer {
			margin-top: -4px !important;

			&.notZero #portfolioContainer-savedPortfolio {
				margin-top: 0;
			}
		}
	}

	#skipLinks a {
		z-index: 2;
	}

	.stickyGlobalHeaderEnabled {
		.headerDropDowns .headerDropDowns-menu {
			transition: visibility 140ms 140ms;
		}
	}
}